import React from 'react';
import HeaderLogo from './Logo';
import HeaderNav from './Nav';

function Header() {
  return (
    <nav className="navbar navbar-expand">
      <div className="container d-flex flex-column flex-lg-row justify-content-between">
        <HeaderLogo />
        <HeaderNav />
      </div>
    </nav>
  );
}

export default Header;
