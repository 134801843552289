import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import LandingPage from '../Components/LandingPage/LandingPage';
import Results from '../Components/Results/Results';
import Biobank from '../Components/Biobank/Biobank';
import Resources from '../Components/Resources/Resources';
import AboutUs from '../Components/About/About';
import { withTracker } from '../Components/GoogleAnalytics/GoogleAnalytics';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="header-container">
          <Header />
        </header>
        <section className="main">
          <Switch>
            <Route path="/" exact component={withTracker(LandingPage)} />
            <Route path="/results" component={withTracker(Results)} />
            <Route path="/biobank" component={withTracker(Biobank)} />
            <Route path="/resources" component={withTracker(Resources)} />
            <Route path="/about" component={withTracker(AboutUs)} />
          </Switch>
        </section>
        <footer className="footer mt-auto">
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
