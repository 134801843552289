import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function HeaderNav() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="navbar-nav-scroll">
      <ul className="navbar-nav bd-navbar-nav flex-row">
        <li className="nav-item">
          <Link
            to="/"
            className={`nav-link ${currentPath === '/' ? 'active' : ''}`}
          >
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/results"
            className={`nav-link ${currentPath === '/results' ? 'active' : ''}`}
          >
            Results
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/biobank"
            className={`nav-link ${currentPath === '/biobank' ? 'active' : ''}`}
          >
            Biobank
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/about"
            className={`nav-link ${currentPath === '/about' ? 'active' : ''}`}
          >
            About
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/resources"
            className={`nav-link ${
              currentPath === '/resources' ? 'active' : ''
            }`}
          >
            Resources
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default HeaderNav;
