import React from 'react';
import GwasDatasetColumns from './gwasDatasetColSpecs.json';

// Render GWAS summary stats column specs table
function GwasColSpecs() {
  return (
    <>
      <p>Dataset column specification:</p>
      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            {GwasDatasetColumns.map((item) => {
              return (
                <tr key={item.label}>
                  <th scope="row">
                    <p className="text-dark mb-0">{item.label}</p>
                  </th>
                  <td>
                    <p className="text-dark mb-0">{item.description}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default GwasColSpecs;
