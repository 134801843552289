import React, { useEffect } from 'react';
import gtag from 'ga-gtag';

const trackingId = () => {
  // available measurement Ids for covid-omics apps
  const trackers = {
    'covid-omics.org': 'G-0R0J6MEE0M',
    'development.covid-omics.org': 'G-9G5CQGBPD7',
  };

  //  determine current hostname
  let analyticsTrackerHostname = document.location.hostname;

  // match hostname to google analytics domain identified for tracker
  if (/^(www\.)?covid-omics.org/.test(analyticsTrackerHostname)) {
    // production app
    analyticsTrackerHostname = 'covid-omics.org';
  } else if (/^development.covid-omics.org/.test(analyticsTrackerHostname)) {
    // test app
    analyticsTrackerHostname = 'development.covid-omics.org';
  } else {
    // catch-all
    analyticsTrackerHostname = 'development.covid-omics.org';
  }

  // use correct tracking Id based on hostname
  const tracker = trackers[analyticsTrackerHostname];

  return tracker;
};

export const withTracker = (WrappedComponent) => {
  const trackPage = (page) => {
    gtag('config', trackingId(), {'page_path': page, 'site_speed_sample_rate' : 100});
  };

  const HOC = (props) => {
    const { location } = props;
    useEffect(() => trackPage(location.pathname), [location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default trackingId;
