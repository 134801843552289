import React from 'react';
import {
  faExternalLinkAlt,
  faInfoCircle,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BiobankPDF from '../../assets/biobank_distribution.pdf';

function Biobank() {
  return (
    <div className="biobank-page-container container">
      <section className="sample-use-agreement py-3 pt-md-5 pb-md-4">
        <h1 className="section-title pb-3">Sample Use Agreement</h1>
        <p>
          We are excited to collaborate with you to learn about the pathogenesis
          of COVID-19 in our collective effort to develop new methods for the
          prevention, diagnosis, and treatment of the SARS-CoV-2 virus.
        </p>
        <p>
          We are equally happy that the biobank resource that we developed is
          being put to valuable use. The purpose of this note is to establish
          expectations for use of the COVID-19 samples.
        </p>
        <p className="font-weight-bold">
          Use of these samples is contingent upon agreeing to these
          expectations:
        </p>
        <ul className="list-group mb-3">
          <li className="list-group-item">
            <p className="mb-0">
              Before a sample request has been fulfilled, the lab must have in
              place appropriate approvals, including IRB and biosafety protocols
              (most work with these samples is considered BSL2 or BSL2+, at the
              discretion of the biosafety committee) as well as university
              approvals for essential COVID-19 research.
            </p>
          </li>
          <li className="list-group-item">
            <p className="mb-0">
              All individuals who use these samples are required to share the
              data generated from these samples with other investigators at
              Stanford. A platform to host this data is available. It is also
              expected that data will be shared with the broader scientific
              community as appropriate (for instance, upon publication).
            </p>
          </li>
          <li className="list-group-item">
            <p className="mb-0">
              All manuscripts generated using data from these samples should be
              shared with the biobank leadership team for review and to decide
              on appropriate authorship from the Stanford Biobank team accordin
              to the guidelines outlined in Fontanarosa P et al. Authorship and
              Team Science. JAMA 2017, 318:24, 2433-2437. The name "Stanford
              COVID-19 BioBank Research Group" should be included in the author
              byline.
            </p>
          </li>
          <li className="list-group-item">
            <p className="mb-0">
              Samples should not be passed on/shared to other groups without IRB
              approval and without consulting the Biobank Leadership group for
              approval.
            </p>
          </li>
        </ul>
        <p>
          <a href={BiobankPDF}>
            <span className="linkout-item-text mr-2">
              Read the full letter about biobank use agreement
            </span>
            <FontAwesomeIcon icon={faFilePdf} />
          </a>
        </p>
      </section>
      <section className="available-samples py-3 pt-md-2 pb-md-4">
        <h1 className="section-title pb-3">Available Samples</h1>
        <p className="font-weight-bold">
          The Stanford COVID-19 biobank has samples from two different studies:
        </p>
        <ul className="list-group mb-3">
          <li className="list-group-item">
            <p>
              55650 Stanford COVID-19 Biobank (Dr. Sam Yang, PI). Patients are
              enrolled in the emergency department with confirmed COVID-19
              infection. Ongoing enrollment.
            </p>
            <p className="text-secondary mb-0">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span className="ml-2">
                Approximate enrollment 10/1/2020: 183 COVID+, of these 108 were
                hospitalized, ~30 PUI (COVID-)
              </span>
            </p>
          </li>
          <li className="list-group-item">
            <p>
              55689 COVID-19 Immunity (Dr. Kari Nadeau, PI). Patients are
              enrolled as outpatients in an urgent care setting for long-term
              followup, not currently enrolling new patients.
            </p>
            <p className="text-secondary mb-0">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span className="ml-2">Approximate enrollment 140 COVID+</span>
            </p>
          </li>
        </ul>
        <p className="font-weight-bold">
          Specimen types available include plasma, serum, PBMC, PAXgene RNA
          whole blood, nasal swab, saliva.
        </p>
      </section>
      <section className="requesting-samples py-3 pt-md-2 pb-md-4">
        <h1 className="section-title pb-3">Requesting Samples</h1>
        <p className="linkout-item">
          <a
            href="https://redcap.stanford.edu/surveys/?s=P3E7H9WAJH"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="linkout-item-text mr-2">
              Use this form to request samples
            </span>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </p>
      </section>
    </div>
  );
}

export default Biobank;
