import EuanImg from '../../assets/contributors/Euan.jpg';
import MattImg from '../../assets/contributors/Matt.jpg';
import JeffImg from '../../assets/contributors/Jeff.jpg';
import CarlosImg from '../../assets/contributors/Carlos.png';
import VickiImg from '../../assets/contributors/Vicki.jpeg';
import MannyImg from '../../assets/contributors/Manny.jpg';
import StephenImg from '../../assets/contributors/Stephen.png';
import SteveImg from '../../assets/contributors/Steve.png';
import KarenImg from '../../assets/contributors/Karen.png';
import JimmyImg from '../../assets/contributors/Jimmy.jpg';
import DavidJMImg from '../../assets/contributors/DavidJM.png';
import DavidAImg from '../../assets/contributors/DavidA.jpg';
import ArchanaImg from '../../assets/contributors/Archana.png';
import BriannaImg from '../../assets/contributors/Brianna.png';
import LilianaImg from '../../assets/contributors/Liliana.png';
import BenjaminImg from '../../assets/contributors/Benjamin.jpg';
import MarceloImg from '../../assets/contributors/Marcelo.png';
import JuliaSalzmanImg from '../../assets/contributors/JuliaSalzman.jpg';
import JuliaPalaciosImg from '../../assets/contributors/JuliaPalacios.jpg';
import SuzanneImg from '../../assets/contributors/Suzanne.jpg';
import ChrisImg from '../../assets/contributors/Chirs.png';
import MajaImg from '../../assets/contributors/Maja.jpeg';
import AlexImg from '../../assets/contributors/Alex.png';

const contributors = [
  {
    name: 'Dr. Matthew Wheeler',
    photo: MattImg,
  },
  {
    name: 'Dr. Euan Ashley',
    photo: EuanImg,
  },
  {
    name: 'Dr. Manuel A. Rivas',
    photo: MannyImg,
  },
  {
    name: 'Dr. Carlos D. Bustamante',
    photo: CarlosImg,
  },
  {
    name: 'Dr. Victoria Nicole Parikh',
    photo: VickiImg,
  },
  {
    name: 'Dr. Benjamin Pinsky',
    photo: BenjaminImg,
  },
  {
    name: 'Dr. Steve Hershman',
    photo: SteveImg,
  },
  {
    name: 'Dr. Jeffrey Christle',
    photo: JeffImg,
  },
  {
    name: 'Dr. David Jimenez-Morales',
    photo: DavidJMImg,
  },
  {
    name: 'Dr. Marcelo Fernández-Viña',
    photo: MarceloImg,
  },
  {
    name: 'Dr. Julia Salzman',
    photo: JuliaSalzmanImg,
  },
  {
    name: 'Dr. Julia Adela Palacios',
    photo: JuliaPalaciosImg,
  },
  {
    name: 'Dr. David Amar',
    photo: DavidAImg,
  },
  {
    name: 'Dr. Stephen Montgomery',
    photo: StephenImg,
  },
  {
    name: 'Dr. Suzanne Tamang',
    photo: SuzanneImg,
  },
  {
    name: 'Dr. Liliana Fernandez',
    photo: LilianaImg,
  },
  {
    name: 'Dr. Alexander Ioannidis',
    photo: AlexImg,
  },
  {
    name: 'Karen Dalton',
    photo: KarenImg,
  },
  {
    name: 'Maja Lichstein Herberg',
    photo: MajaImg,
  },
  {
    name: 'Archana Raja',
    photo: ArchanaImg,
  },
  {
    name: 'Rob Bierman',
    photo: null,
  },
  {
    name: 'Chris Ray Hughes',
    photo: ChrisImg,
  },
  {
    name: 'Darren Liu',
    photo: null,
  },
  {
    name: 'Jimmy Zhen',
    photo: JimmyImg,
  },
  {
    name: 'Brianna Tucker',
    photo: BriannaImg,
  },
];

export const labs = [
  {
    name: 'Ashley Lab',
    photo: EuanImg,
    url: 'https://ashleylab.stanford.edu/',
  },
  {
    name: 'Rivas Lab',
    photo: MannyImg,
    url: 'https://med.stanford.edu/rivaslab.html',
  },
  {
    name: 'Bustamante Lab',
    photo: CarlosImg,
    url: 'https://bustamantelab.stanford.edu/',
  },
  {
    name: 'Montgomery Lab',
    photo: StephenImg,
    url: 'http://med.stanford.edu/montgomerylab.html',
  },
];

export default contributors;
