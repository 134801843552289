import React from 'react';
import featureImageCoronavirus from '../../assets/coronavirus-green.png';
import featureImageResearch from '../../assets/coronavirus-research.png';

function Feature() {
  function handleBtnClick() {
    window.open('https://survey.covid-omics.org/');
    return false;
  }

  return (
    <div className="feature-container d-flex flex-column container">
      <section className="feature-section feature-section__first row d-flex flex-column justify-content-center">
        <div className="cta-button-container">
          <button
            type="button"
            className="btn btn-dark btn-lg"
            onClick={handleBtnClick}
            onKeyPress={handleBtnClick}
          >
            COVID-19 Host Genome Survey
          </button>
        </div>
        <p className="cta-tagline text-muted">
          Team up with Stanford to advance our understanding of COVID-19
        </p>
      </section>
      <section className="feature-section feature-section__between row">
        <div className="feature-image feature-image__left col-md-4">
          <img
            src={featureImageCoronavirus}
            className="w-100"
            alt="Coronavirus"
          />
        </div>
        <div className="feature-content col-md-8">
          <h3>What is SARS-CoV-2?</h3>
          <p>
            CoV are found globally in humans and many different animal species. They are classified in the Orthocoronaviridae subfamily (order: Nidovirales, subordination: Cornidovirineae, family: Coronaviridae). CoV are enveloped viruses with a lipid membrane derived from the host cell, in which viral surface proteins are embedded. The proteins protruding from the viral membrane (especially the spike [S]protein) give these pathogens their characteristic halo-like appearance under the electron microscope, which has led to the name corona (Latin: garland, crown). In late December 2019, several cases of pneumonia of unknown origin were reported from China, which in early January 2020 were announced to be caused by a novel coronavirus. The virus was later denominated severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2).
          </p>
        </div>
      </section>
      <section className="feature-section feature-section__last row">
        <div className="feature-content col-md-8">
          <h3>Our approach to fight the virus</h3>
          <p>
            The study is designed to characterize the genomic epidemiology of severe acute respiratory syndrome coronavirus 2 (SARS coronavirus 2, or SARS-CoV-2) and define host genetic effects on the outcome of viral infection. 1000 COVID19-positive outpatients, inpatients and ICU patients at Stanford Health Care are planned to be recruited as part of this research study. Some of the questions that we are trying to address: are there germline genetic factors that protect against infection? Once infected, why are some hosts able to control the infection and not progress to disease while others succumb to Acute Respiratory Distress Syndrome? What role does the evolution of the virus play in differences in how the pandemic plays out in different populations?
          </p>
        </div>
        <div className="feature-image feature-image__right col-md-4">
          <img src={featureImageResearch} className="w-100" alt="Coronavirus" />
        </div>
      </section>
    </div>
  );
}

export default Feature;
