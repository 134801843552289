import React from 'react';
import PropTypes from 'prop-types';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FileList({ files }) {
  return (
    <ul className="list-group mb-4">
      {files.map((item) => {
        return (
          <li key={item.name} className="list-group-item">
            <p className="mb-0">
              <a href={item.url} download className="dataset-download">
                <FontAwesomeIcon icon={faFileArchive} />
                <span className="ml-2">{item.name}</span>
              </a>
            </p>
          </li>
        );
      })}
    </ul>
  );
}

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

FileList.defaultProps = {
  files: [],
};

export default FileList;
