import React from 'react';
import ReactDOM from 'react-dom';
import { install } from 'ga-gtag';
import trackingId from './Components/GoogleAnalytics/GoogleAnalytics';
import App from './Containers/App';
import * as serviceWorker from './serviceWorker';
import './main.css';

install(trackingId());

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
