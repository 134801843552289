import React from 'react';

function Hero() {
  return (
    <div className="hero-container">
      <div className="w-100 bg-cover">
        <div className="container d-flex flex-column">
          <div className="hero-tagline row align-items-center justify-content-start py-12">
            <h1 className="col-xl-7 col-lg-8 col-md-12">
              Multi-omics research data and analysis on COVID-19
            </h1>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-row justify-content-end">
        <div className="hero-caption row pt-2">
          <p>Image by CDC / Alissa Eckert, MS; Dan Higgins, MAMS</p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
