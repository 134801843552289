import React from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import contributors, { labs } from './Contributors';

function AboutUs() {
  // Renders individual contributor
  function contributor(person) {
    return (
      <article key={person.name} className="contributor col mb-4">
        <figure className="contributor-image">
          <div className="media-wrapper">
            {person.photo ? (
              <img src={person.photo} alt={person.name} />
            ) : (
              <FontAwesomeIcon icon={faUser} />
            )}
          </div>
        </figure>
        <section className="contributor-name">
          <h5>{person.name}</h5>
        </section>
      </article>
    );
  }
  const renderContributors = contributors.map((item) => contributor(item));

  // Renders individual contributor
  function lab(person) {
    return (
      <a key={person.name} href={person.url}>
        <figure className="lab col mb-4">
          <div className="media-wrapper">
            <img src={person.photo} alt={person.name} />
          </div>
          <section className="lab-name">
            <h5>{person.name}</h5>
          </section>
        </figure>
      </a>
    );
  }

  const renderLabs = labs.map((item) => lab(item));

  return (
    <div className="about-page-container container">
      <section className="about-main-content py-3 pt-md-5 pb-md-4">
        <h1 className="section-title pb-3">About Us</h1>
        <div>
          <p>
            We are a group of doctors, researchers, and scientists passionate
            about expanding our understanding of human disease to improve
            patient care. Our team consists of specialists in genetics,
            genomics, cardiovascular medicine, bioinformatics, and clinical
            research. Individuals who have contributed to this project include:
          </p>
          <div className="contributors-list row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mt-4">
            {renderContributors}
          </div>
        </div>
        <div>
          <p>
            If you are interested in learning more about our team and other
            research we do, please visit:
          </p>
          <div className="lab-list row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mt-4">
            {renderLabs}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
