import React from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImgPCA from '../../assets/pca_plot.png';
import ImgCOVID19Severity from '../../assets/ancestry_covid19_severity_plot.png';
import GwasColSpecs from './GwasColSpecs';
import FileList from './FileList';
import { GwasFiles, Covid19SeverityFiles } from './sumStatFiles';

function Results() {
  return (
    <div className="results-page-container container">
      <section className="pca-plot py-3 pt-md-5 pb-md-4">
        <h1 className="section-title pb-3">Population Stratification</h1>
        <div>
          <p>
            Low-pass DNA sequencing data from COVID-19 patients were merged with
            a reference set (1KG, HGDP, and SGDP). The plot below shows the
            first two principal components of 631 COVID-19 patients, where the
            pie plots show the ADMIXTURE proportions across the known
            populations taken from the reference (EUR European, AMR Ad Mixed
            American, SAS South Asian, WAS West Asian, EAS East Asian, AFR
            African, OCE Oceanian).
          </p>
          <figure className="pca-plot-wrapper mt-4 mb-3 py-2 d-flex justify-content-center">
            <img
              src={ImgPCA}
              className="figure-img img-fluid rounded"
              alt="PCA"
            />
          </figure>
        </div>
      </section>
      <section className="summary-statistics py-3 pt-md-5 pb-md-4">
        <h1 className="section-title pb-3">GWAS Summary Statistics</h1>
        <div>
          <p>
            GWAS results from the first two batches were contributed to the HGI.
            These data contained 109 EUR COVID-19 patients and 191 population
            controls. The summary statistics below were computed with adjustment
            for sex and 10 principal components. Data are provided in hg38
            coordinates.
          </p>
          <FileList files={GwasFiles} />
          <GwasColSpecs />
        </div>
      </section>
      <section className="summary-statistics py-3 pt-md-5 pb-md-4">
        <h1 className="section-title pb-3">
          COVID19 Severity Summary Statistics: Admixture mapping, human
          transcriptomics, metagenomics and HLA typing
        </h1>
        <div>
          <figure className="covid19-severity-plot-wrapper mt-4 mb-3 py-2 d-flex justify-content-center">
            <img
              src={ImgCOVID19Severity}
              className="figure-img img-fluid rounded"
              alt="Inferred Genetic Ancestries"
            />
          </figure>
          <p>
            The SARS-CoV-2 pandemic has differentially impacted populations of
            varied race, ethnicity and socioeconomic status. Local genetic
            ancestry inference represents a powerful tool to examine genetic
            risk within multi-ancestry genomes independent of these confounding
            social constructs. Here, we leverage a pandemic tracking strategy in
            which we sequence viral and host genomes and transcriptomes from
            1,327 nasopharyngeal swab residuals and integrate them with digital
            phenotypes from electronic health records. We demonstrate
            over-representation of Hispanic/Latino individuals in the SARS-CoV-2
            positive populations by majority-vote ancestry inference.
            Genome-wide-association disaggregated by admixture mapping reveals
            regions of chromosomes 5 and 14 associated with COVID19 severity
            within African and Oceanic local ancestries, respectively.
            Phylodynamic tracking of consensus viral genomes revealed no
            association with disease severity or majority-vote inferred
            ancestry. We further present summary data from a multi-omic
            investigation of human-leukocyte-antigen (HLA) typing,
            nasopharyngeal microbiome and human transcriptomics that reveal
            metagenomic and HLA associations with severe COVID19 infection. This
            work demonstrates the power of multi-omic pandemic tracking and
            local ancestry inference to reveal distinct epidemiologic, genetic
            and biological associations for those at the highest risk.
          </p>
          <p className="mb-4">
            <a
              href="https://www.medrxiv.org/content/10.1101/2021.08.04.21261547v1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="mr-2">View Full Manuscript</span>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </p>
          <FileList files={Covid19SeverityFiles} />
        </div>
      </section>
    </div>
  );
}

export default Results;
