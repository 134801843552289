import React from 'react';
import Hero from './Hero';
import Feature from './Feature';

function LandingPage() {
  return (
    <div className="landing-page-container">
      <Hero />
      <Feature />
    </div>
  );
}

export default LandingPage;
