import React from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Resources() {
  return (
    <div className="resources-page-container container">
      <section className="resources-main-content py-3 pt-md-5 pb-md-4">
        <h1 className="section-title pb-3">Resources</h1>
        <ul className="list-group">
          <li className="list-group-item">
            <p className="linkout-item mb-0">
              <a
                href="https://www.covid19hg.org/results/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="linkout-item-text mr-2">
                  The COVID-19 Host Genetics Initiative
                </span>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            </p>
          </li>
          <li className="list-group-item">
            <p className="linkout-item mb-0">
              <a
                href="https://www.ncbi.nlm.nih.gov/sars-cov-2/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="linkout-item-text mr-2">
                  NCBI SARS-CoV-2 Resources
                </span>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            </p>
          </li>
          <li className="list-group-item">
            <p className="linkout-item mb-0">
              <a
                href="https://survey.covid-omics.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="linkout-item-text mr-2">
                  COVID-19 Host Genome Survey
                </span>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            </p>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Resources;
