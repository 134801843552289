import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-covid-omics.png';

function HeaderLogo() {
  return (
    <Link to="/" className="navbar-brand header-logo">
      <img src={logo} className="app-logo" alt="COVID-Omics" loading="lazy" />
    </Link>
  );
}

export default HeaderLogo;
